import React, { useState } from "react";

const Context = React.createContext({});

export function UserContext({ children }) {
  const [user, setUser] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [category, setCategory] = useState(null);

  return (
    <Context.Provider value={{ user, setUser, category, setCategory, menuOpen, setMenuOpen }}>
      {children}
    </Context.Provider>
  );
}

export default Context;
