import React, { Suspense } from "react";
import "./App.css";

import { Route } from "wouter";

import { UserContext } from "./context/UserContext";

  const Home = React.lazy(() => import("./pages/Home"));
  const Catalogo = React.lazy(() => import("./pages/Catalogo"));
  const Services = React.lazy(() => import("./pages/Services"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Product = React.lazy(() => import("./pages/Producto"));
const Login = React.lazy(() => import("./pages/Login"));
const SingUp = React.lazy(() => import("./pages/SingUp"));
const Compra = React.lazy(() => import("./pages/Compra"));
const Profile = React.lazy(() => import("./pages/Profile"));

export default function App() {
  return (
    <div className="App">
      <Suspense fallback={null}>
        <UserContext>
          <Route component={Home} path="/" />
          <Route component={Home} path="/home" />
          <Route component={Login} path="/login" />
          <Route component={SingUp} path="/registrate" />
          <Route component={Catalogo} path="/catalogo/:category" />
          <Route component={Catalogo} path="/catalogo" />
          <Route component={Services} path="/services" />
          <Route component={Contact} path="/contact" />
          <Route component={Compra} path="/compra" />
          <Route component={Profile} path="/perfil" />
          <Route component={Product} path="/producto/:idProducto" />
        </UserContext>
      </Suspense>
    </div>
  );
}
